import React from 'react';
import { useState, useEffect } from "react";
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, Button, Alert, Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { api, catchErrorAPI } from '../../../api/apiConfig';

const EventRegistrationPage = () => {
    let navigate = useNavigate();

    const [message, setMessage] = useState("");
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await api.get(`/event/65b2c4a57933bbe8ac9bba2f`);
                setEvent(response.data);
                document.title = `${response.data.label} | UAC`;
                setLoading(false);
            } catch (err) {
                catchErrorAPI(err);
            }
        }
        fetchData();
    }, []);


    const [initialValues, setInitialValues] = useState({
        firstName: '',
        lastName: '',
        emailAdress: '',
        emailAdressConfirmation: '',
        nbKids: 0,
        nbAdults: 2,
        nbVege: 0,
        note: '',
        rgpdConsent: false,
    });

    const validationSchema = Yup.object().shape({
        firstName: Yup.string().required('Un prénom est requis'),
        lastName: Yup.string().required('Un nom est requis'),

        emailAdress: Yup.string()
            .required('Un email est requis')
            .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                'Veuillez fournir une adresse email valide'
            ),
        emailAdressConfirmation: Yup.string()
            .required('La confirmation d\'email est requise')
            .oneOf([Yup.ref('emailAdress')], 'Les adresses email ne correspondent pas'),
        rgpdConsent: Yup.boolean().oneOf([true], 'Vous devez donner votre consentement pour utiliser vos données'),
        note: Yup.string().required('Un 2ème joueur est requis'),
    });

    const addInscription = async (values) => {

        try {

            values.inscriptionDate = new Date(Date.now());
            values.status = "registered";

            let data = await api.post(`/registration/${event._id}`, values);

            navigate(`/belote/confirmation/${data.data._id}`);

        } catch (error) {
            setMessage({ error: true, msg: error.response.data });
        }


    };

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container>
            <Row className='py-3 d-flex align-items-center justify-content-between' xs={3} md={3} lg={3}>
                <Col xs={1} md={2} lg={2} className="text-left">
                    <span className="returnButton" onClick={() => { navigate(-1) }}>
                        <FontAwesomeIcon id="faChevronLeft" icon={faArrowLeft} />
                        <span className="d-none d-md-inline"> Retour</span>
                    </span>
                </Col>
                <Col xs={10} md={10} lg={10} className="text-center"><h1 className="titleh1"><span className="jauneTitre">Inscription {event.label}</span></h1></Col>
                <Col xs={1} md={2} lg={2}></Col>
            </Row>
            <Row className='mt-4'>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Inscription</span></h3><br />


                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values) => {
                            addInscription(values);

                        }}
                    >
                        {({ values, errors, touched }) => (
                            <Form>
                                <label htmlFor="firstName">Prénom (1er joueur)</label> <br />
                                <Field id="firstName" name="firstName" className="fieldTitleNews" /><br />

                                <label htmlFor="lastName">Nom (1er joueur)</label> <br />
                                <Field id="lastName" name="lastName" className="fieldTitleNews" /><br />

                                <label htmlFor="emailAdress">Email (1er joueur)</label> <br />
                                <Field type="email" id="emailAdress" name="emailAdress" className="fieldTitleNews" /><br />

                                <label htmlFor="emailAdressConfirmation">Confirmer l'adresse mail (1er joueur)</label> <br />
                                <Field type="email" id="emailAdressConfirmation" name="emailAdressConfirmation" className="fieldTitleNews" /><br /><br />


                                <label htmlFor="note">Prénom Nom (2 ème joueur)</label> <br />
                                <Field id="note" name="note" className="fieldTitleNews" /><br />

                                {/*
                                <label htmlFor="nbAdults">Nombre d'adultes ({event.generalPrice} €)</label><br />
                                <Field name="nbAdults" as="select" defaultValue="0">
                                    <option value="0"> 0 </option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </Field> <br />

                                <label htmlFor="nbVege">Nombre d'adultes <u>végétariens</u> ({event.generalPrice} €)</label><br />
                                <Field name="nbVege" as="select" defaultValue="0">
                                    <option value="0"> 0 </option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </Field> <br />

                                <label htmlFor="nbKids">Nombre d'enfants ( -12 ans / {event.kidPrice} €)</label><br />
                                <Field name="nbKids" as="select" defaultValue="0">
                                    <option value="0"> 0 </option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                </Field>*/} <br /> <br />

                                <label htmlFor="rgpdConsent" id="rgpdConsentLabel" className='rgpdConsentLabel'>
                                    <Field type="checkbox" name="rgpdConsent" id="rgpdConsent" className="rgpdConsent" /> {` `}
                                    J'accepte que mes données soient utilisées dans le cadre unique de l'inscription à cet évenement
                                </label>

                                <div>
                                    <p><ErrorMessage name="firstName" /></p>
                                    <p><ErrorMessage name="lastName" /></p>
                                    <p><ErrorMessage name="emailAdress" /></p>
                                    <p><ErrorMessage name="emailAdressConfirmation" /></p>
                                    <p><ErrorMessage name="rgpdConsent" /></p>
                                    <p><ErrorMessage name="note" /></p>
                                </div>

                                {message?.msg && (
                                    <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                                        {message?.msg}
                                    </Alert>)
                                }

                                <Button className="mt-3 buttonJaune" size="lg" type="submit" >Valider l'inscription <FontAwesomeIcon icon={faArrowRight} /></Button>
                            </Form>)}
                    </Formik>





                </Col>
            </Row>
        </Container>
    )
}

export default EventRegistrationPage