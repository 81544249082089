import './App.css';

import {
  Routes,
  Route,
  useNavigate,
  useLocation
} from "react-router-dom";


import { Container, Navbar, Nav, NavDropdown, Col, Offcanvas, Row, Modal, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from 'react';

import HomePage from './pages/Home/HomePage';
import GroupePage from './pages/Groupe/GroupePage';
import FichePage from './pages/FicheAthlete/fichePage';
import AdminHomePage from './pages/Admin/AdminHomePage';
import LoginPage from './pages/Admin/LoginPage';
import TopSaison from './pages/Top/TopSaison';
import { PrivateRoute, UserContext } from './Utils/userContext';

import { useEffect } from "react";
import ListCompetititon from './pages/Competition/ListeCompetition';
import FicheCompetiton from './pages/Competition/FicheCompetition';

import SearchBar from './components/SearchBar/SearchBar';
import { cacheExpiration } from './Utils/utilis';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faXmark, faCaretDown } from '@fortawesome/free-solid-svg-icons'
import TopClub from './pages/Top/TopClub';
import TeamPage from './pages/Club/TeamPage';
import GroupesPage from './pages/Club/GroupesPage';
import NewsPage from './pages/Club/NewsPage';
import StadiumPage from './pages/Club/StadiumPage';
import JoinPage from './pages/Club/JoinPage';
import OneNewsPage from './pages/Club/OneNewsPage';
import { api, catchErrorAPI } from './api/apiConfig';
import RegisterPage from './pages/User/RegisterPage';
import NotFound from './pages/NotFound';
import EventPage from './pages/Event/Event/EventPage';
import EventRegistrationPage from './pages/Event/Event/EventRegistrationPage';
import EventConfirmationPage from './pages/Event/Event/EventConfirmationPage';
import RGPDPage from './pages/RGPDPage';
import CompetitionPage from './pages/Event/Competition/CompetitionPage';
import CompRegistrationPage from './pages/Event/Competition/CompRegistrationPage';
import CompConfirmationPage from './pages/Event/Competition/CompConfirmationPage';
import CompCancelPage from './pages/Event/Competition/CompCancelPage';
import ResetPassword from './pages/User/ResetPassword';
import EventCancelPage from './pages/Event/Event/EventCancelPage';
import StagePage from "./pages/Event/Stage/StagePage";
import StageRegistrationPage from "./pages/Event/Stage/StageRegistrationPage";
import PdfPage from "./pages/Inscription/pdf";
import FormulaireInscription from "./pages/Inscription/FormulaireInscription";

window.listeGroupes = [{ label: "Sprint", id: "pXhU6ukBYgAagg3btLT2" }, { label: "400 metres", id: "JF2rQglmuJ8v1bkPisd0" }, { label: "Demi-fond", id: "HvJRLWTsc6i14WEiAhZ8" }, { label: "Fond", id: "P1gWjBAErtqCsg0mixIk" }, { label: "Lancers", id: "YowvXvpohfBqF2zSoaNv" }, { label: "Sauts", id: "NeVLXonEua1jPKDzoct2" }];


window.listeCategories = ["Cadet H", "Cadet F", "Scolaire H", "Scolaire F", "Junior H", "Junior F",
  "Senior H", "Senior F", "Master H", "Master F"];

window.listeCategoriesTop = ["Benjamins", "Pupilles", "Minimes", "Cadets", "Scolaires", "Juniors", "Masters", "TC"];

/*
window.listeEpreuvesIndoor = [{ label: "60 m", type: "seconde", metre: 60 }, { label: "60 m Haies", type: "seconde", metre: 60 }, { label: "200 m", type: "seconde", metre: 200 },
{ label: "400 m", type: "minute", metre: 400 }, { label: "4 x 200 m", type: "minute", metre: 800 }, { label: "4 x 400 m", type: "minute", metre: 1600 },
{ label: "800 m", type: "minute", metre: 800 }, { label: "1000 m", type: "minute", metre: 1000 }, { label: "1500 m", type: "minute", metre: 1500 }, { label: "3000 m", type: "minute", metre: 3000 }, { label: "5000 m", type: "minute", metre: 5000 }, { label: "10000 m", type: "minute", metre: 10000 },
{ label: "Longueur", type: "distance" }, { label: "Hauteur", type: "distance" }, { label: "Triple saut", type: "distance" }, { label: "Poids", type: "distance" }];

window.listeEpreuvesOutdoor = [{ label: "100 m", type: "seconde", metre: 100 }, { label: "100 m Haies", type: "seconde", metre: 100 }, { label: "110 m Haies", type: "seconde", metre: 110 },
{ label: "200 m", type: "seconde", metre: 200 },

{ label: "300 m", type: "minute", metre: 300 }, { label: "300 m Haies", type: "minute", metre: 300 }, { label: "400 m", type: "minute", metre: 400 },{ label: "600 m", type: "minute", metre: 600 }, { label: "800 m", type: "minute", metre: 800 },{ label: "1000 m", type: "minute", metre: 1000 }, { label: "1500 m", type: "minute", metre: 1500 },{ label: "2000 m", type: "minute", metre: 2000 }, { label: "3000 m", type: "minute", metre: 3000 },
{ label: "400 m Haies", type: "minute", metre: 400 },

{ label: "4 x 100 m", type: "minute", metre: 400 }, { label: "4 x 200 m", type: "minute", metre: 800 }, { label: "4 x 400 m", type: "minute", metre: 1600 },

{ label: "Hauteur", type: "distance" }, { label: "Longueur", type: "distance" }, { label: "Triple saut", type: "distance" }, { label: "Perche", type: "distance" }, { label: "Disque", type: "distance" },
{ label: "Javelot", type: "distance" }, { label: "Poids", type: "distance" }];


 */

window.listeEpreuvesCross = [{ label: "Court", type: "minute" }, { label: "Long", type: "minute" }]

function App() {
  let navigate = useNavigate();
  const [userAuth, setUserAuth] = useState(null);

  const location = useLocation();

  const [athletes, setAthletes] = useState([]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);



  useEffect(() => {
    window.gtag("event", "page_view", {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  useEffect(() => {
    setUserAuth(JSON.parse(localStorage.getItem('authUser')));

    const cachedData = localStorage.getItem("athletesList");

    const cachedDataAthletesTimestamp = localStorage.getItem("timestampAthletesList");
    const currentTime = new Date().getTime();

    if (cachedData && cachedDataAthletesTimestamp && currentTime - cachedDataAthletesTimestamp < cacheExpiration) {
      setAthletes(JSON.parse(cachedData));
    } else {

      const fetch = async () => {
        try {
          const response = await api.get('/athletes');
          setAthletes(response.data);
          localStorage.setItem("athletesList", JSON.stringify(response.data));
          localStorage.setItem("timestampAthletesList", currentTime);
        } catch (err) {
          catchErrorAPI(err);
        }
      }
      fetch();

    }
  }, [])

  return (
    <UserContext.Provider value={{ userAuth, setUserAuth }}>
      <div className="App">
        <header>
          <Navbar bg="black" variant="black" expand="lg" id='navbarMenu' >
            <Container>
              <Navbar.Brand id='brand' onClick={() => { navigate("/") }}>
                <Image src="https://firebasestorage.googleapis.com/v0/b/uacperformance.appspot.com/o/logo_uac.png?alt=media&token=baec86d0-c0f2-4729-b4db-92d11d2dfa50" width="30" height="30" className="d-inline-block align-top" />
                <span id='logoUAC'>UAC</span> <span id='logoPerformance'>Chatelineau</span>
              </Navbar.Brand>

              <Navbar.Toggle onClick={handleShowModal} className="ms-auto" >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Navbar.Toggle>
              <Navbar.Toggle data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" onClick={handleShow} />


              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link className="navLinkMenu" onClick={() => { navigate("/") }}>Accueil</Nav.Link>

                  <NavDropdown title={<span className='navLinkMenu'>Le club <FontAwesomeIcon icon={faCaretDown} /></span>}>
                    <NavDropdown.Item onClick={() => { navigate("/actualites") }}>Actualités</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { navigate("/equipes") }}>Nos équipes</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { navigate("/groupes") }}>Nos groupes & horaires</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { navigate("/stade") }}>Informations pratiques</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => { navigate("/inscription") }}>Nous rejoindre</NavDropdown.Item>
                  </NavDropdown>

                  <Nav.Link className="navLinkMenu" onClick={() => { navigate("/groupe/647143af2f9a8ead9968b46d") }}>Athlètes</Nav.Link>

                  <NavDropdown title={<span className='navLinkMenu'>Top 5 de la saison <FontAwesomeIcon icon={faCaretDown} /></span>}>
                    <NavDropdown.Item onClick={() => { navigate("/TopSaison/Hommes/Indoor") }}>Indoor Hommes</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { navigate("/TopSaison/Femmes/Indoor") }}>Indoor Femmes</NavDropdown.Item>

                    <NavDropdown.Divider />

                    <NavDropdown.Item onClick={() => { navigate("/TopSaison/Hommes/Outdoor") }}>Outdoor Hommes</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { navigate("/TopSaison/Femmes/Outdoor") }}>Outdoor Femmes</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title={<span className='navLinkMenu'>Top 10 du club <FontAwesomeIcon icon={faCaretDown} /></span>}>
                    <NavDropdown.Item onClick={() => { navigate("/TopClub/Hommes/Indoor/TC") }}>Indoor Hommes</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { navigate("/TopClub/Femmes/Indoor/TC") }}>Indoor Femmes</NavDropdown.Item>

                    <NavDropdown.Divider />

                    <NavDropdown.Item onClick={() => { navigate("/TopClub/Hommes/Outdoor/TC") }}>Outdoor Hommes</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => { navigate("/TopClub/Femmes/Outdoor/TC") }}>Outdoor Femmes</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>


              <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">

                <SearchBar id="seachBarMenu" placeholder="Rechercher un athlète" data={athletes} />


                {userAuth &&
                  <Nav.Link className="navLinkMenu justify-content-end" onClick={() => { navigate("/admin") }}>Admin</Nav.Link>
                }
                {!userAuth &&
                  <Nav.Link className="navLinkMenu justify-content-end" onClick={() => { navigate("/login") }}>Se connecter</Nav.Link>
                }
              </Navbar.Collapse>

              <Offcanvas tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" show={show} placement="end" onHide={handleClose}>
                <Offcanvas.Header>
                  <Offcanvas.Title id='brand'><span id='logoUAC'>UAC</span> <span id='logoPerformance'>Chatelineau</span></Offcanvas.Title>
                  <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleClose} />
                </Offcanvas.Header>
                <Offcanvas.Body>

                  <Nav className="me-auto flex-column">
                    <Nav.Link className="navLinkMenu offcanvasNav" onClick={() => { navigate("/"); handleClose() }}>Accueil</Nav.Link>

                    <NavDropdown className='offcanvasNav' title={<span className='navLinkMenu'>Le club</span>}>
                      <NavDropdown.Item onClick={() => { navigate("/actualites"); handleClose() }}>Actualités</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => { navigate("/equipes"); handleClose() }}>Nos équipes</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => { navigate("/groupes"); handleClose() }}>Nos groupes & horaires</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => { navigate("/stade"); handleClose() }}>Informations pratiques</NavDropdown.Item>
                      <NavDropdown.Divider />
                      <NavDropdown.Item onClick={() => { navigate("/inscription"); handleClose() }}>Nous rejoindre</NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Link className="navLinkMenu offcanvasNav" onClick={() => { navigate("/groupe/Sprint"); handleClose() }}>Athlètes</Nav.Link>

                    <NavDropdown className='offcanvasNav' title={<span className='navLinkMenu'>Top 5 de la saison</span>}>
                      <NavDropdown.Item onClick={() => { navigate("/TopSaison/Hommes/Indoor"); handleClose() }}>Indoor Hommes</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => { navigate("/TopSaison/Femmes/Indoor"); handleClose() }}>Indoor Femmes</NavDropdown.Item>

                      <NavDropdown.Divider />

                      <NavDropdown.Item onClick={() => { navigate("/TopSaison/Hommes/Outdoor"); handleClose() }}>Outdoor Hommes</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => { navigate("/TopSaison/Femmes/Outdoor"); handleClose() }}>Outdoor Femmes</NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown className='offcanvasNav' title={<span className='navLinkMenu'>Top 10 du club</span>}>
                      <NavDropdown.Item onClick={() => { navigate("/TopClub/Hommes/Indoor/TC"); handleClose() }}>Indoor Hommes</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => { navigate("/TopClub/Femmes/Indoor/TC"); handleClose() }}>Indoor Femmes</NavDropdown.Item>

                      <NavDropdown.Divider />

                      <NavDropdown.Item onClick={() => { navigate("/TopClub/Hommes/Outdoor/TC"); handleClose() }}>Outdoor Hommes</NavDropdown.Item>
                      <NavDropdown.Item onClick={() => { navigate("/TopClub/Femmes/Outdoor/TC"); handleClose() }}>Outdoor Femmes</NavDropdown.Item>
                    </NavDropdown>




                    {userAuth &&
                      <Nav.Link className="navLinkMenu" onClick={() => { navigate("/admin"); handleClose() }}>Admin</Nav.Link>
                    }
                    {!userAuth &&
                      <Nav.Link className="navLinkMenu" onClick={() => { navigate("/login"); handleClose() }}>Se connecter</Nav.Link>
                    }



                  </Nav>

                </Offcanvas.Body>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#f7de3a" fill-opacity="1" d="M0,160L34.3,170.7C68.6,181,137,203,206,181.3C274.3,160,343,96,411,101.3C480,107,549,181,617,218.7C685.7,256,754,256,823,224C891.4,192,960,128,1029,96C1097.1,64,1166,64,1234,85.3C1302.9,107,1371,149,1406,170.7L1440,192L1440,320L1405.7,320C1371.4,320,1303,320,1234,320C1165.7,320,1097,320,1029,320C960,320,891,320,823,320C754.3,320,686,320,617,320C548.6,320,480,320,411,320C342.9,320,274,320,206,320C137.1,320,69,320,34,320L0,320Z"></path></svg>
              </Offcanvas>


            </Container>
          </Navbar>
        </header>

        <Modal show={showModal} onHide={handleCloseModal} backdrop="static"
          keyboard={false} fullscreen={true} >
          <Modal.Header className='headerModal'>
            <Modal.Title className='titleModal'>Rechercher un athlète</Modal.Title>
            <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleCloseModal} />
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center modalBody">
            <Container>
              <Row className='mb-5'><Col ><SearchBar id="searchBarPopup" placeholder="Rechercher un athlète" data={athletes} onCloseModal={handleCloseModal} /></Col></Row>
              <Row xs={1} md={1} lg={1}>
                {
                  window.listeGroupes.map(groupe => {
                    return (
                      <Col className='pt-2' key={groupe}><div className='linkModal' onClick={() => { navigate(`/groupe/${groupe.label}`); handleCloseModal() }}>{groupe.label}</div></Col>
                    )
                  })
                }
              </Row>
            </Container>


          </Modal.Body>
        </Modal>

        <main>
          <Routes>
            <Route path='/' element={<HomePage />} />

            <Route path='/TopSaison/Hommes/Indoor' element={<TopSaison sexe="H" saison="Indoor" />} />
            <Route path='/TopSaison/Femmes/Indoor' element={<TopSaison sexe="F" saison="Indoor" />} />
            <Route path='/TopSaison/Hommes/Outdoor' element={<TopSaison sexe="H" saison="Outdoor" />} />
            <Route path='/TopSaison/Femmes/Outdoor' element={<TopSaison sexe="F" saison="Outdoor" />} />

            <Route path='/TopClub/:sexe/:saison/:categorie' element={<TopClub />} />

            
            <Route path='/groupe/*' element={<GroupePage />} />
            <Route path='/athlete/:idAthlete' element={<FichePage />} />
            <Route path='/competitions' element={<ListCompetititon />} />
            <Route path='/competition/:idCompetiton' element={<FicheCompetiton />} />

            <Route path='/equipes' element={<TeamPage />} />
            <Route path='/groupes' element={<GroupesPage />} />
            <Route path='/actualites' element={<NewsPage />} />
            <Route path='/actualites/:idActualite' element={<OneNewsPage />} />
            <Route path='/stade' element={<StadiumPage />} />
            <Route path='/inscription' element={<JoinPage />} />
            
            <Route path='/register' element={<RegisterPage />} />

            <Route path='/admin/*' element={
              <PrivateRoute>
                <AdminHomePage />
                
              </PrivateRoute>
            } />

            <Route path='/belote' element={<EventPage></EventPage>} />
            <Route path='/belote/inscription' element={<EventRegistrationPage></EventRegistrationPage>} />
            <Route path='/belote/confirmation/:idConfirmation' element={<EventConfirmationPage></EventConfirmationPage>} />
            <Route path='/belote/annulation/:idEvent' element={<EventCancelPage></EventCancelPage>} />
            

            <Route path='/MeetingCloture' element={<CompetitionPage></CompetitionPage>} />

{/*             <Route path='/MeetingCloture/inscription' element={<CompRegistrationPage></CompRegistrationPage>} />
            <Route path='/MeetingCloture/confirmation/:idConfirmation' element={<CompConfirmationPage></CompConfirmationPage>} />
            <Route path='/MeetingCloture/annulation/:idEvent' element={<CompCancelPage></CompCancelPage>} />


            <Route path='/stage' element={<StagePage></StagePage>} />
            <Route path='/stage/inscription' element={<StageRegistrationPage></StageRegistrationPage>} />
            <Route path='/stage/confirmation/:idConfirmation' element={<CompConfirmationPage></CompConfirmationPage>} />


            <Route path='/attestation' element={<FormulaireInscription></FormulaireInscription>} />
            <Route path='/sign/:idInscription' element={<PdfPage></PdfPage>} />
 */}
            <Route path='/login' element={<LoginPage />} />
            <Route path='/resetPassword' element={<ResetPassword />} />
            <Route path='/rgpd' element={<RGPDPage />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

        </main>
        <footer>
          <div id='divFooter'>
            <div id='footer'>
              <span>Site réalisé par le Staff Technique UAC | </span>
              
              <span onClick={() => { navigate(`/rgpd`)}} className='linkRGPD'>Protection des données</span>
            </div>
          </div>
        </footer>
      </div>
    </UserContext.Provider>
  );
}

export default App;
