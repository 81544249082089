import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";

const EventPage = () => {

    let navigate = useNavigate();

    useEffect(() => {
        document.title = "Belote | UAC";
    }, []);

    return (
        <Container>
            <Row className="text-center pt-3">
                <Col>
                    <h1 className="titleh1"><span className="jauneTitre">Belotte 2024</span></h1>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Présentation</span></h3><br />
                    <p>
                        Rejoignez-nous le 23 février 2024 pour une soirée belote dès 19h00 !
                    </p>
                </Col>
            </Row>
            <Row>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Informations pratiques</span></h3><br />
                    <ul>
                        <li><b>Le 23 Février 2024 à 19h</b></li>
                        <li>Début du tournois à 19h30</li>
                        <li>Tournoi belote 4 manches</li>
                        <li>Équipe de 2 au prix de 20 €/équipe avec 8 tickets boissons compris</li>
                        <li>Boissons & croque-monsieurs sur place</li>
                        <li>Rue de la Plaine, Chatelineau</li>
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col className='mx-2 bloc'>
                    <h3 className="titleh1"><span className="jaune">Inscriptions</span></h3><br />
                    <p>
                        <ul>
                            <li>Inscription obligatoire <u>avant le mercredi 21 février </u></li>
                            <li>Payement par virement sur le compte du club</li>
                        </ul>
                    </p>

                    {/*<Button className="mt-3 buttonJauneExterne" size="lg" onClick={() => { navigate(`/SoireeDesEtoiles/annulation/64e12db609a1828c1ee3cdab`) }} >Modifier / Annuler <FontAwesomeIcon icon={faArrowRight} /></Button> {` `} */}

                     <Button className="mt-3 buttonJaune" size="lg" onClick={() => { navigate("/belote/inscription") }}>Inscrire une équipe <FontAwesomeIcon icon={faArrowRight} /></Button>
                </Col>
            </Row>
        </Container>
    )
}

export default EventPage