import React from 'react';
import Carousel from 'react-bootstrap/Carousel';

function CarouselComponent() {
  return (
    <Carousel>
      <Carousel.Item interval={2000} >
        <img
          className="d-block w-100 carousel-image"
          src="https://firebasestorage.googleapis.com/v0/b/uacperformance.appspot.com/o/news%2F87744476?alt=media&token=8900172a-b621-45a6-87ff-3796382303d0"
          alt="groupe1"
        />
      </Carousel.Item>
        <Carousel.Item interval={2000}>
            <img
                className="d-block w-100 carousel-image"
                src="https://firebasestorage.googleapis.com/v0/b/uacperformance.appspot.com/o/carousel%2F452157406_868904808628544_3561154883863967855_n.jpg?alt=media&token=6a4b0ff5-3223-4b8e-90fb-a25c7968f5b8"
                alt="groupe2"
            />
        </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 carousel-image"
          src="https://firebasestorage.googleapis.com/v0/b/uacperformance.appspot.com/o/news%2F74258634?alt=media&token=bf0abb5d-7a19-473b-9bf4-afc3e1023c8b"
          alt="groupe3"
        />
      </Carousel.Item>
      <Carousel.Item interval={2000}>
        <img
          className="d-block w-100 carousel-image"
          src="https://firebasestorage.googleapis.com/v0/b/uacperformance.appspot.com/o/news%2F30530961?alt=media&token=6114142b-3a62-4308-9bdc-590691444859"
          alt="groupe4"
        />
      </Carousel.Item>

    </Carousel>
  );
}

export default CarouselComponent;
