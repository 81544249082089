import React from 'react';
import { useState } from "react";
import './searchBar.css';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMagnifyingGlass, faXmark } from '@fortawesome/free-solid-svg-icons';
import { getInitial } from '../../Utils/utilis';



function SearchBar({placeholder, data, onCloseModal}) {
    let navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        const newFilter = data.filter((value) => {
            return value.prenom.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(searchWord.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
        });

        if(searchWord === ""){
            setFilteredData([]);
        }else{
            setFilteredData(newFilter);
        }
    }
    
    const handleClick = (id) => {
        console.log(id);
        setFilteredData([]);
        setWordEntered("");
        navigate(`/athlete/${id}`);
        if(onCloseModal){
            onCloseModal();
        }
    }

    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    }

  return (
    <div className='search'>
        <div className='searchInputs'>
            <input type="text" placeholder={placeholder} value={wordEntered} onChange={handleFilter} />
            <div className='searchIcon'>
                {wordEntered.length === 0 ? <FontAwesomeIcon className='icon' id="faMagnifyingGlass" icon={faMagnifyingGlass}/> : 
                <FontAwesomeIcon className='icon' id="clearBtn" icon={faXmark} onClick={clearInput} />}
                
            </div>
        </div>
        { filteredData.length != 0 && (
        <div className='dataResult'>
            {filteredData.slice(0,4).map((value, key) => {
                return <a className='dataItem' onClick={() => {handleClick(value._id)}}>{getInitial(value.prenom, value.nom)}</a>
            })}
        </div>
        )}
    </div>
  )
}

export default SearchBar