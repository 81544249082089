import React, {useState, useEffect, useRef} from 'react';
import {Container, Row, Col, Button, Spinner, Alert} from 'react-bootstrap';
import SignatureCanvas from 'react-signature-canvas';
import { PDFDocument } from 'pdf-lib';
//import pdfFile from './docwithfield.pdf'; // Chemin vers le PDF local
import { Buffer } from 'buffer';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {useLocation, useParams} from "react-router-dom";
import {api, catchErrorAPI} from "../../api/apiConfig";
import {pdfToLead} from "../../Utils/odoo";

// Assurez-vous que Buffer est disponible globalement
window.Buffer = Buffer;

const PdfPage = () => {
    const [pdfDataUri, setPdfDataUri] = useState(null);
    const { idInscription } = useParams();
    let [inscription, setInscription] = useState();
    let [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [canvasSize, setCanvasSize] = useState({ width: 300, height: 150 });
    const signTutorLu = useRef(null);
    const signTutorSign = useRef(null);
    const signMinorLu = useRef(null);
    const signMinorSign = useRef(null);


    useEffect(() => {
        document.title = "Attestation sur l'Honneur - PDF | UAC";

        const fetchData = async () => {
            try{
                const response = await api.get(`/inscription/${idInscription}`);
                setInscription(response.data);

                setLoading(false);
            }catch(err){
                catchErrorAPI(err);
            }
        }
        fetchData();

        const updateCanvasSize = () => {
            if (window.innerWidth <= 768) { // Taille mobile
                setCanvasSize({ width: 250, height: 150 });
            } else { // Taille PC
                setCanvasSize({ width: 400, height: 200 });
            }
        };

        // Mettre à jour la taille au chargement de la page
        updateCanvasSize();

        // Mettre à jour la taille lors du redimensionnement de la fenêtre
        window.addEventListener('resize', updateCanvasSize);
        return () => window.removeEventListener('resize', updateCanvasSize);
    }, [idInscription]);

    const handleSubmit = async () => {

        try {

            let output = await api.put(`/inscriptionPDF`,inscription);

            console.log("output " + output.data.leadId);

            await fillForm(output.data.leadId)

            setIsLoading(false);

        } catch (error) {
            setMessage({ error: true, msg: error.message });
            setIsLoading(false);
        }
    }

    const addSignature = async (pdfDoc, signatureObject, x, y) => {
        const dataURL = signatureObject.current.toDataURL('image/png');
        const response = await fetch(dataURL);
        const signatureImageBytes = await response.arrayBuffer();

        const signatureImage = await pdfDoc.embedPng(signatureImageBytes);
        const signatureDims = signatureImage.scale(0.3); // Adjust the scale as needed

        console.log("Width : " + signatureDims.width)
        console.log("Height : " + signatureDims.height)

        // Add the signature to the form
        const pages = pdfDoc.getPages();
        const firstPage = pages[0];
        firstPage.drawImage(signatureImage, {
            x: x, // x coordinate of the signature position
            y: y, // y coordinate of the signature position
            width: signatureDims.width,
            height: signatureDims.height,
        });
    };





    async function fillForm(leadId) {
        const formPdfBytes = await fetch("pdfFile").then(res => res.arrayBuffer());

        const pdfDoc = await PDFDocument.load(formPdfBytes);

        const form = pdfDoc.getForm();

        form.getTextField('datefrom').setText('24');
        form.getTextField('dateto').setText('25');
        form.getTextField('lastname').setText(inscription.lastname);
        form.getTextField('firstname').setText(inscription.firstname);
        form.getTextField('birthdate_date').setText(new Date(inscription.birthdate_date).toLocaleDateString("fr-FR"));
        form.getTextField('category').setText(inscription.category);
        form.getTextField('nationality').setText('NAT');
        form.getTextField('street').setText(inscription.street);
        form.getTextField('numstreet').setText(inscription.numStreet);
        form.getTextField('locality').setText(inscription.city);
        form.getTextField('email').setText(inscription.email);
        form.getTextField('mobile').setText(inscription.mobile);


        // Get the signature image
        await addSignature(pdfDoc, signTutorLu, 70,50);
        await addSignature(pdfDoc, signTutorSign, 170,50);

        if(inscription.minor){
            await addSignature(pdfDoc, signMinorLu, 300,50);
            await addSignature(pdfDoc, signMinorSign, 400,50);
        }



        const pdfBytes = await pdfDoc.save();

        const pdfDataUri = `data:application/pdf;base64,${Buffer.from(pdfBytes).toString('base64')}`;
        setPdfDataUri(pdfDataUri);
        /*
        // Créer une URL de données pour le PDF
        const pdfDataUri = `data:application/pdf;base64,${Buffer.from(pdfBytes).toString('base64')}`;
        setPdfDataUri(pdfDataUri);

        const formData = new FormData();
        const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
        formData.append('pdfFile', pdfBlob, 'filled_form.pdf');

        console.log(pdfBytes.toString('base64'))
        */

    /*
        // Créez un objet FormData pour envoyer le PDF avec d'autres données du formulaire
        const formData = new FormData();
        let pdfBlob = await new Blob([pdfBytes], { type: 'application/pdf' })
        await formData.append('pdfFile', pdfBlob , 'filled_form.pdf');

        console.log(pdfBytes);
        console.log(pdfBlob);
        console.log(formData);


        let data = await api.put(`/addPDF/${leadId}`,formData);
        console.log(data);

     */
    }

    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col className="mb-3" >
                    <h1 className="titleh1"><span className="jauneTitre">Attestation à signer</span></h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Container className="containerAttestation ">
                        <Row className="mb-3">
                            <Col><u><i><b>LIGUE BELGE FRANCOPHONE D’ATHLETISME</b></i></u></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="text-center"><u><b>ATTESTATION SUR L’HONNEUR</b></u></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col className="ps-5 p-3">En vue de l’obtention de la licence pour la</Col>
                            <Col className="borderSaisonDate p-3">SAISON SPORTIVE 2024 / 2025 </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><p>
                                La pratique régulière d’une activité physique ou sportive est bénéfique pour la santé. Elle constitue un moyen naturel de prévenir et/ou freiner l’évolution de maladies fréquentes (maladies cardiovasculaires, cancers, anxiété, dépression, diabète…). <br/>
                                Néanmoins, le sport doit être pratiqué dans de bonnes conditions et en toute sécurité. Dans certains cas, un examen médical préalable à la pratique du sport est conseillé.
                            </p></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><p>
                                Chaque athlète fait du sport sous sa propre responsabilité. Il atteste sur l’honneur de l’absence de contre-indication à la pratique du sport conformément à l’article 12 des statuts de la Ligue Belge Francophone d’Athlétisme et à l’article 6.1.7. du Règlement d’Ordre Intérieur de la Ligue Belge Francophone d’Athlétisme. Cette attestation est établie afin de respecter les obligations découlant du décret du 3 avril 2014 relatif à la prévention des risques pour la santé dans le sport et de ses arrêtés d’exécution.
                            </p></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col><p>
                                Il reconnait avoir pris connaissance de l’avis de la commission médicale de la LBFA qui conseille un examen préventif en médecine sportive dans les cas suivants (liste non-exhaustive) :
                            </p></Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                « Cet examen <b><i>est fortement conseillé</i></b> pour les athlètes :
                                <ul>
                                    <li>Qui débutent leur carrière sportive.</li>
                                    <li>Qui font de la compétition intensive.</li>
                                    <li>Qui ont des maladies chroniques telles que le diabète, asthme, des rhumatismes,…</li>
                                    <li>Qui ont des antécédents familiaux de problèmes cardiaques.</li>
                                    <li>Qui ont des problèmes de rythmes cardiaques (trop lent, trop rapide).</li>
                                    <li>Qui ont des douleurs ou des compressions dans la poitrine.</li>
                                    <li>Qui ont des problèmes de pertes de conscience ou de vertiges.</li>
                                    <li>Masculins de + de 40 ans.</li>
                                    <li>Féminines de + de 50 ans.</li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                Cet examen <b><i>est également vivement conseillé</i></b> aux athlètes qui ont deux facteurs de risques cardio-vasculaire ou plus :
                                <ul>
                                    <li>Tension élevée</li>
                                    <li>Trop de cholestérol</li>
                                    <li>Taux de glucose trop élevé</li>
                                    <li>Fumer</li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <p className="paragraphPdf">
                                    Je soussigné, Nom : <span className="inputPdf">{inscription.lastname}</span> Prénom : <span className="inputPdf">{inscription.firstname}</span>
                                </p>
                                <p className="paragraphPdf">
                                    Date de naissance : <span className="inputPdf">{new Date(inscription.birthdate_date).toLocaleDateString("fr-FR")}</span> Catégorie : <span className="inputPdf">{inscription.category}</span> Nationnalité : <span className="inputPdf">BELGE</span>
                                </p>
                                <p className="paragraphPdf">
                                    Adresse : <span className="inputPdf">{inscription.street}</span> N° : <span className="inputPdf">{inscription.numStreet}</span>
                                </p>
                                <p className="paragraphPdf">
                                    Localité : <span className="inputPdf">{inscription.zip} {inscription.city}</span>
                                </p>
                                <p className="paragraphPdf">
                                    Adresse mail : <span className="inputPdf">{inscription.email}</span> Tel. : <span className="inputPdf">{inscription.mobile}</span>
                                </p>
                                <p>
                                    déclare avoir lu le texte concernant l’examen préventif de médecine sportive, comprendre et accepter les éventuels risques d’une pratique sportive.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <p>
                                    <u>Dopage</u>
                                </p>
                                <p>
                                    Le(s) représentant(s) légal(aux) d’un sportif mineur accepte(ent) que le mineur soit assisté d’un membre du personnel d’encadrement du cercle lors d’un contrôle antidopage, en leur absence sur les lieux de contrôle.
                                </p>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                Fait à ...... le {new Date(inscription.inscriptionDate).toLocaleDateString("fr-FR")}
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                Signature de l’athlète <b>ET</b> de son représentant légal (pour le sportif mineur) précédé de la mention «Lu et approuvé»
                            </Col>
                        </Row>

                        <Row className="mb-3">
                            <Col>
                                <h2>Lu et approuvé {inscription.minor ? "(tuteur)":""}</h2>
                                <SignatureCanvas ref={signTutorLu} penColor='black'
                                                 canvasProps={{width: canvasSize.width, height: canvasSize.height,  className: 'sigCanvas' }} />
                                <h2>Signature {inscription.minor ? "(tuteur)":""}</h2>
                                <SignatureCanvas ref={signTutorSign} penColor='black'
                                                 canvasProps={{width: canvasSize.width, height: canvasSize.height,  className: 'sigCanvas' }} />

                            </Col>
                            {inscription.minor &&
                            <Col>
                                <h2>Lu et approuvé (mineur)</h2>
                                <SignatureCanvas ref={signMinorLu} penColor='black'
                                                 canvasProps={{width: canvasSize.width, height: canvasSize.height,  className: 'sigCanvas' }} />
                                <h2>Signature (mineur)</h2>
                                <SignatureCanvas ref={signMinorSign} penColor='black'
                                                 canvasProps={{width: canvasSize.width, height: canvasSize.height, className: 'sigCanvas' }} />

                            </Col>}
                        </Row>
                        <Row className="mb-3">
                            <Col>
                                <b>N.B.: L’attestation doit être conservée au cercle durant trois années civiles complètes.</b>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Row>
                <Button className="mt-3 buttonJaune" size="lg" onClick={handleSubmit} disabled={isLoading}>{isLoading ? 'Chargement...' : 'Valider l\'inscription'} <FontAwesomeIcon icon={faArrowRight} /></Button>
                {message?.msg && (
                    <Alert variant={message?.error ? "danger" : "success"} dismissible onClose={() => setMessage("")}>
                        {message?.msg}
                    </Alert>)
                }
            </Row>
            <Row>
                <Col>
                    {pdfDataUri && (
                        <embed src={pdfDataUri} type="application/pdf" width="100%" height="700px" />
                    )}
                </Col>
            </Row>



        </Container>
    );
};

export default PdfPage;
