import { Container, Row, Card, Col, Spinner, Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAnglesRight, faArrowDown, faXmark } from '@fortawesome/free-solid-svg-icons'
import { faNewspaper } from '@fortawesome/free-regular-svg-icons'
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api, catchErrorAPI } from "../../api/apiConfig";

function LastNews() {

    const [loading, setLoading] = useState(true);
    let navigate = useNavigate();
    const [news, setNews] = useState([]);
    const [actuSelect, setActuSelect] = useState(null);

    const [showModalNews, setShowModalNews] = useState(false);

    const handleCloseModalNews = () => {
        setShowModalNews(false);
      }
    
      const handleShowModalNews = (actu) => {
    
        setActuSelect(actu);
        setShowModalNews(true);
      }

    useEffect(() => {
        const fetchNews = async () => {
            try{
              const response = await api.get('/news/latest');
              setNews(response.data);
              setLoading(false);
            }catch(err){
              catchErrorAPI(err);
            }
          }
          fetchNews();
    }, [])


    if (loading) {
        return (
            <Container className="bloc">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                </Spinner>
            </Container>
        )
    }

    return (
        <Container className="bloc">
            <Row className="text-center">
                <h1 className="titleHomePage" onClick={() => {navigate(`/actualites`)}}><span className="jaune"><FontAwesomeIcon icon={faNewspaper} /> Actualités</span></h1>
            </Row>
            <Row xs={1} md={2} lg={4}>
                {
                    news.map((actu) => {
                        return (
                            <Col className='pt-3 d-flex flex-wrap' key={actu._id}>
                                <Card className='flex-grow-1' onClick={() => handleShowModalNews(actu)}>
                                    {actu.imgUrl && actu.extension != "pdf" ? <Card.Img variant="top" className="cardImgNews" src={actu.imgUrl} /> : <Card.Img variant="top" className="cardImgNews" src="https://firebasestorage.googleapis.com/v0/b/uacperformance.appspot.com/o/news%2F69014975?alt=media&token=514f6a05-3698-4010-8dcc-9d2c053cf378" />}
                                    
                                    <Card.Body >
                                        <Card.Title className="cardTitle">{actu.titre} <FontAwesomeIcon icon={faAnglesRight} className="iconTransparence" /> </Card.Title>
                                        <Card.Subtitle className="mb-2 text-muted cardText">{new Date(actu.dateCreation).toLocaleDateString("fr-FR")}</Card.Subtitle>
                                        {/*<Card.Text >*/}
                                        {/*    {actu.contenu.substring(0, 100)}{actu.contenu.length > 100 ? "..." : ""}*/}

                                        {/*</Card.Text>*/}

                                    </Card.Body>

                                </Card>
                            </Col>
                        )
                    })
                }
            </Row>
            <Row className="mt-2">
                <Col>
                    <Card className='flex-grow-1 cardYellow' onClick={() => {navigate(`/actualites`)}}>
                        <Card.Body className="p-1">
                            <Card.Title className="text-center cardTitle">Toutes les actualités <FontAwesomeIcon icon={faAnglesRight} className="iconTransparence" /> </Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {actuSelect &&
        <Modal size='lg' show={showModalNews} onHide={handleCloseModalNews} backdrop="static"
          keyboard={false} fullscreen={false} >
          <Modal.Header className='headerModal'>

            <Modal.Title className='titleModal'>
              {actuSelect.titre}
            </Modal.Title>
            <FontAwesomeIcon icon={faXmark} className="iconClose" onClick={handleCloseModalNews} />
          </Modal.Header>
          <Modal.Body className="d-flex justify-content-center">
            <Container>
              <Row>{new Date(actuSelect.dateCreation).toLocaleDateString("fr-FR")} - {actuSelect.type} - {actuSelect.auteur}</Row>
              <Row><hr /></Row>
              <Row className='mb-3' style={{ whiteSpace: "pre-line" }}>{actuSelect.contenu}</Row>

              {actuSelect.urlLink && <Row className='m-4 px-3'><Button href={actuSelect.urlLink} target="_blank">{actuSelect.labelLink ? actuSelect.labelLink : 'Lien'}</Button></Row>}



              {actuSelect.imgUrl &&
                <div>{actuSelect.extension === "pdf" ? 
                <Row className='m-4 px-3'>
                  <Button  href={actuSelect.imgUrl} target="_blank"><FontAwesomeIcon className='iconPDF' icon={faArrowDown} />PDF</Button> </Row>: <Row className='p-3'><img src={actuSelect.imgUrl} /></Row>}</div>
              }

            </Container>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalNews}>
            Fermer
          </Button>
        </Modal.Footer>
        </Modal>}
        </Container>
    )
}

export default LastNews;